import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  requestManager,
  TimesTypes,
  fpsManager,
  appWSM2021Config,
  modes,
  AppWSM2021DifficultyTypes,
  playersConfig,
  PlayersSecondResultTypes,
  playersManager,
  corePhasesManager,
  lightConfig,
  CorePhases,
  tutorialManager
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  trainingConfig,
  velocityConfig,
  translatesReplacements,
  sponsorsConfig
} from './config'
import {
  TexturesNames,
  MaterialsNames,
  DisciplinePhases,
  CustomGameEvents
} from './types'
import { worldEnv } from './entities/env/WorldEnv'
import { player } from './entities/athlete/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import { opponentsManager } from './entities/athlete/opponent/OpponentsManager'
import { linesManager } from './entities/env/LinesManager'
import { wind } from './entities/athlete/Wind'
import * as Sentry from '@sentry/vue'
import type { Athlete } from './entities/athlete'
import { audioHelper } from './audioHelper/AudioHelper'
import { cameraShake } from './entities/athlete/player/CameraShake'
import {
  debugState,
  trainingState,
  tutorialState
} from '@/stores'
import { loadingState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** koeficient pre pohyb v cieli po zastaveni hraca */
  private finishVectorToLookAt = new THREE.Vector3()

  /** Ci sa ma zobrazit specialna kamera v cieli */
  private specialFinishCamera = false

  /** Frame counter pre ciel */
  private frameCounterFinish = 0

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()

    /**
     * Nastavenie specialnych sponzorov
     */
    this.setSpecialSponsor()

    // musime este nastavit, aby nebolo intro v treningu
    corePhasesManager.showIntro = !modes.isTrainingMode()

    lightConfig.lightVector.set(0, 5, 0)
    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.athlete].meshesArray || [],
        meshesAnisotropy: ['TrackCycling_Mesh001', 'TrackCycling_Mesh001_12'],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Nastavenie specialneho sponzora
   */
  private setSpecialSponsor(): void {

    const timeNow = Date.now()
    const random = THREE.MathUtils.randFloat(0, 1)
    const sponsorBannerToShow = {
      name: '',
      percent: 0,
      textureName: ''
    }

    // zatial kontrolujeme iba bannery
    sponsorsConfig.banners.forEach((sponsor) => {

      if (!sponsor.active) return

      // sponsor.name
      sponsor.times.forEach((slot) => {

        if (slot.from <= timeNow && slot.to > timeNow && random < slot.percent) {

          sponsorBannerToShow.name = sponsor.name
          sponsorBannerToShow.percent = slot.percent
          sponsorBannerToShow.textureName = sponsor.textureName
          console.log('vyberame sponzora pre bannery: ', sponsorBannerToShow)

        }

      })

    })

    // ak je nejaky sponzor - vyberie sa posledny
    if (sponsorBannerToShow.name !== '') {

      // zmenime assety - vymazeme povodny a dame novy
      delete texturesConfig[TexturesNames.colorGradient]
      materialsConfig[MaterialsNames.colorGradient].textureName = sponsorBannerToShow.textureName

    } else {

      // tu dame vsetky specialne
      delete texturesConfig[TexturesNames.colorGradientVshosting]

    }

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 14

    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.athleteRaceBlackMan,
      TexturesNames.athleteRaceBlackWoman,
      TexturesNames.athleteRaceMulattoMan,
      TexturesNames.athleteRaceMulattoWoman,
      TexturesNames.athleteRaceWhiteMan,
      TexturesNames.athleteRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.athleteRacePrefix}${playerInfo.race}`)

    // pridame superov, ak su
    opponentsManager.getOpponentsIds().forEach((uuid) => {

      const opponentInfo = playersManager.getPlayerById(uuid)
      usedTextures.push(`${opponentInfo?.sex}/${TexturesNames.athleteRacePrefix}${opponentInfo?.race}`)

    })

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    // musime nastavit prefix pre meshe, aby nam nekolidovali meshe zo skyboxu s inymi
    game.prefixMeshesGroupEnabled = true

    // musime zakazat default shadow plane, lebo budeme pouzivat teren
    game.shadowsManager.enableCreatingShadowPlaneOnStart = false
    game.shadowsManager.setShadowCameraFrustumPlaneDimensions(12, 12, 12, 12)

    opponentsManager.rewriteOpponentsData()
    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      opponentsManager.getOpponentsIds(),
      this.getIgnoredTexturesNames(),
      TexturesNames.athleteRacePrefix
    )

    const tweenSettingsForCameraStates = modes.isTrainingMode() ?
      trainingConfig.tweenSettingsForCameraStates :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(0.1, 1200)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    // UI update
    stateManager.allowDirectionState()

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.secondResultType = PlayersSecondResultTypes.numberArray

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()

    // const loadingState = stateManager.getLoadingState()
    const loadingStateInstance = loadingState().$state
    const newState = {
      ...loadingStateInstance,
      loadingProgress: gameStats.getLoadingProgress()
    }
    stateManager.setLoadingState(newState)

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    console.log(data)

    Sentry.setContext('minigame', { id: requestManager.MINIGAME_START_ID })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    linesManager.init()

    // musime si najskor urcit, kto bude na ktorych poziciach zacinat
    disciplinePhasesManager.setStartPositionsForPlayers()

    wind.setValue()

    // poradie pre prepare animacie, aby boli animacie rozne pre roznych hracov
    const orderAnimationsPrepare = [0, 1, 2, 3, 4, 5].sort(() => Math.random() - 0.5)

    /** hrac musi byt pred protihracmi, kvoli vypoctom */
    player.create(
      disciplinePhasesManager.dataForPlayersStartPositions[playersManager.getPlayer().uuid],
      disciplinePhasesManager.dataForPlayersStartOrder[playersManager.getPlayer().uuid],
      orderAnimationsPrepare[0]
    )
    opponentsManager.create(
      disciplinePhasesManager.dataForPlayersStartPositions,
      disciplinePhasesManager.dataForPlayersStartOrder,
      orderAnimationsPrepare
    )
    player.afterOpponentsCreated()
    player.worldEnvLinesManager.setAllAthletes([...opponentsManager.getOpponents(), player])
    opponentsManager.getOpponents().forEach((opponent) => {

      opponent.worldEnvLinesManager.setAllAthletes([...opponentsManager.getOpponents(), player])

    })

    worldEnv.create()

    this.setUpDebug()

    // tiene
    // game.shadowsManager.attachPlaneToObject(player.athleteObject)
    worldEnv.setUpShadows()

    /*
     * TEMP
     * game.scene.fog = new THREE.Fog( 0xcccccc, 20, 400 )
     */

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      worldEnv,
      inputsManager,
      player,
      opponentsManager,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      tutorialManager: tutorialManager,
      setHUDVisibility: () => (debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE,
      linesManager,
      playersManager
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()

    // listener na dobehnutie hraca do ciela
    window.addEventListener(CustomGameEvents.playerFinished, () => {

      opponentsManager.setDnfIfNotReachedFinish()

    })

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    disciplinePhasesManager.update()
    player.updateBeforePhysics(disciplinePhasesManager.actualPhase)
    opponentsManager.updateBeforePhysics()
    this.checkFpsRequest()
    if (disciplinePhasesManager.actualPhase > DisciplinePhases.start) {

      this.setSlipStreams()

    }

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }
    player.updateAfterPhysics()
    opponentsManager.updateAfterPhysics()

    this.actualCameraPosition.copy(player.getPosition())

    if (
      (disciplinePhasesManager.actualPhase > DisciplinePhases.start &&
      disciplinePhasesManager.actualPhase < DisciplinePhases.finish) &&
      !disciplinePhasesManager.phaseRunning.needsInstalerpForStart() &&
      !this.specialFinishCamera
    ) {

      this.actualCameraQuaternion.slerp(
        player.getQuaternion(),
        gameConfig.cameraQuaternionLerp
      )

    } else {

      this.actualCameraQuaternion.copy(player.getQuaternion())

    }

    const finish = disciplinePhasesManager.actualPhase === DisciplinePhases.finish
    if (!this.specialFinishCamera && finish) {

      this.frameCounterFinish += 1
      if (this.frameCounterFinish >= cameraConfig.finish.delayFrames) this.setSpecialFinishCamera()

    }
    if (this.specialFinishCamera) {

      // reset kvoli opakovaniam
      if (finish) {

        this.actualCameraPosition.copy(cameraConfig.finish.positionCamera)

      } else {

        this.resetFinishCamera()

      }

    }

    const instalerp =
      [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase) ||
      disciplinePhasesManager.phaseRunning.needsInstalerpForStart()

    const quatSettings = this.specialFinishCamera ?
      undefined :
      {
        lerpCoef: instalerp ? 1 : 0.1,
        newQuat: player.getQuaternion().clone()
      }

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [/* hill.hillMesh */],
      cameraConfig.distanceFromGround,
      (
        cameraManager.isThisCameraState(CameraStates.discipline) ||
        cameraManager.isThisCameraState(CameraStates.disciplineOutro) ||
        (finish && !this.specialFinishCamera)
      ),
      quatSettings
    )

    if (this.specialFinishCamera) this.manageCameraInFinish()

    stateManager.setUpdateTimeState()

    if (modes.isTutorial()) tutorialFlow.update()

    audioHelper.update()
    cameraShake.update()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    player.updateAnimations(delta)
    opponentsManager.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

  /**
   * Nastavenie specialnej kamery v cieli
   */
  private setSpecialFinishCamera(): void {

    this.specialFinishCamera = true
    const camera = cameraManager.getMainCamera()
    camera.zoom = cameraConfig.finish.zoom
    camera.updateProjectionMatrix()

  }

  /**
   * Sprava kamery v cieli
   */
  private manageCameraInFinish(): void {

    const camera = cameraManager.getMainCamera()
    const playerPosition = player.getPosition()
    this.finishVectorToLookAt.set(playerPosition.x, playerPosition.y + 1, playerPosition.z)
    camera.lookAt(this.finishVectorToLookAt)

  }

  /**
   * Resetovanie veci pre finish kameru
   */
  private resetFinishCamera(): void {

    this.finishVectorToLookAt.set(0, 0, 0)
    this.specialFinishCamera = false
    this.frameCounterFinish = 0
    const camera = cameraManager.getMainCamera()
    camera.zoom = 1
    camera.updateProjectionMatrix()

  }

  /**
   * Nastavenie slipStream pre vsetkych atletov
   */
  public setSlipStreams(): void {

    const opponents = opponentsManager.getOpponents()

    opponents.forEach(opponent => {

      this.setIsAthleteInSlipstream(opponent, [...opponents, ...[player]])

    })
    this.setIsAthleteInSlipstream(player, opponents)

  }

  /**
   * Vypocet isInSlipstream
   * @param checkedAthlete - atlet, ktoremu settujeme isInSlipStream
   * @param athletes - vsetci atleti s ktorymi porovnavame
   */
  private setIsAthleteInSlipstream(checkedAthlete: Athlete, athletes: Array<Athlete>): void {

    const {
      slipStreamZone,
      minDistanceSlipstream,
      minDistanceSlipstreamEnd,
      minDistLastLap
    } = velocityConfig
    const actualPercent = checkedAthlete.worldEnvLinesManager.getActualPercent()
    const slipstreamZone =
            slipStreamZone * checkedAthlete.worldEnvLinesManager.oneMeterInPercent
    const safeZoneBehindLastLap =
            minDistLastLap * checkedAthlete.worldEnvLinesManager.oneMeterInPercent
    let safeZoneBehind =
            minDistanceSlipstream * checkedAthlete.worldEnvLinesManager.oneMeterInPercent
    if (checkedAthlete.isEnd) {

      safeZoneBehind =
                minDistanceSlipstreamEnd * checkedAthlete.worldEnvLinesManager.oneMeterInPercent

    }
    checkedAthlete.isInSlipStream = false
    checkedAthlete.inSafeZoneOfAthlete = undefined
    checkedAthlete.inSafeZoneOfAthleteLastLap = undefined
    const pathToCheck = checkedAthlete.worldEnvLinesManager.pathIndex

    if (gameConfig.debugSlipStream) {

      this.debugSlipStream(
        checkedAthlete.worldEnvLinesManager.playerPath?.getPointAt(actualPercent - slipstreamZone),
        checkedAthlete.uuid
      )

    }
    if (gameConfig.debugLungeOffset) {

      this.debugSlipStream(
        checkedAthlete
          .worldEnvLinesManager.playerPath?.getPointAt(checkedAthlete.worldEnvLinesManager.getLastPercent() +
          checkedAthlete.worldEnvLinesManager.lungeOffset),
        checkedAthlete.uuid
      )

    }
    athletes.sort((a, b) => {

      return a.reactionDistance > b.reactionDistance ? 1 : -1

    })
    athletes.forEach((opponent) => {

      if (
        checkedAthlete.uuid === opponent.uuid ||
        pathToCheck !== opponent.worldEnvLinesManager.pathIndex
      ) {

        return

      }

      const opponentActualPercent = opponent.worldEnvLinesManager.getActualPercent()
      if (
        opponentActualPercent > actualPercent &&
        (
          opponentActualPercent + 1 - slipstreamZone < actualPercent ||
          opponentActualPercent - slipstreamZone < actualPercent
        )
      ) {

        checkedAthlete.isInSlipStream = true
        checkedAthlete.athleteOfSlipStream = opponent

      }
      if (
        checkedAthlete.last200m &&
        opponentActualPercent > actualPercent &&
        (
          opponentActualPercent + 1 - safeZoneBehindLastLap < actualPercent ||
          opponentActualPercent - safeZoneBehindLastLap < actualPercent
        )
      ) {

        checkedAthlete.inSafeZoneOfAthleteLastLap = opponent

      }
      if (
        opponentActualPercent > actualPercent &&
        (
          opponentActualPercent + 1 - safeZoneBehind < actualPercent ||
          opponentActualPercent - safeZoneBehind < actualPercent
        )
      ) {

        checkedAthlete.inSafeZoneOfAthlete = opponent

      }

    })

  }

  /**
   * Zobrazenie debug gul za hracmi, pokial siaha slipstream
   * @param position - pozicia konca slipstream
   * @param uuid - uuid atleta
   * @returns void
   */
  private debugSlipStream(position: THREE.Vector3 | undefined, uuid: string): void {

    if (!position) return
    const name = `debug-${uuid}`

    const sphere = game.scene.getObjectByName(name)

    if (sphere) {

      sphere.position.set(position.x, position.y + gameConfig.yPlayerCorrection, position.z)
      return

    }

    const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)
    const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
    const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
    meshSphere.position.set(position.x, position.y + gameConfig.yPlayerCorrection, position.z)
    meshSphere.name = name
    game.scene.add(meshSphere)

  }

}
